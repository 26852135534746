/** @jsx jsx */
import Layout from '../layouts/Layout';
import { jsx } from '@emotion/core';
import {
  Card,
  Container,
  CardBody,
  CardHeader,
  CardTitle,
} from '@nimles/react-web-components';
import { DefaultStaticQuery } from '../components/DefaultStaticQuery';
import { useEffect, useMemo } from 'react';
import { navigate } from 'gatsby';
import { useDispatch, useSelector } from 'react-redux';
import {
  createOrganization,
  createUser,
  loadCurrentUser,
  signInUser,
} from '@nimles/react-redux';
import { BannerSection } from '../components/banner/BannerSection';
import styled from '@emotion/styled';
import { RootState } from '../redux/types';
import { UserModel } from '@nimles/models/lib/identity';
import { createPassword, getImageUrl } from '../utils';
import { OrganizationModel } from '@nimles/models/lib/organization-management';
import { RegisterOrganizationForm } from '../components/forms/registerOrganization/RegisterOrganizationForm';
import { PageModel } from '@nimles/models';

const Title = styled.h1`
  color: #555;
  margin-top: 0;
`;

const ConnectPage = ({ cms, location, site }) => {
  const dispatch = useDispatch();
  const currentUser = useSelector<RootState, UserModel>(
    ({ currentUser }) => currentUser.user
  );
  const organization = useSelector<RootState, OrganizationModel>(
    ({ organizations }) => organizations.selected
  );

  useEffect(() => {
    if (organization) {
      navigate('/company');
    }
  }, [organization]);

  const page: PageModel = useMemo(
    () =>
      cms.pages.edges
        .map((edge) => edge.node)
        .find((page) => page.uniqueName === 'login'),
    [cms.pages.edges]
  );

  const meta = {
    title: page?.title,
    description: page?.summary,
    image: getImageUrl(page?.imageId),
  };

  const handleSubmit = async (
    user: UserModel,
    organization: OrganizationModel
  ) => {
    if (!currentUser?.id) {
      const password = user.password || createPassword();

      const userResponse = await dispatch(createUser({ ...user, password }));
      await dispatch(signInUser({ username: user.username, password }));
      await dispatch(loadCurrentUser());
    }
    await dispatch(createOrganization(organization));
  };

  return organization ? null : (
    <Layout
      header
      footer
      meta={meta}
      location={location}
      siteMetadata={site.siteMetadata}
    >
      <BannerSection graphic>
        <Container flex justify="center" display="flex">
          <Card>
            <CardHeader>
              <CardTitle>Register your Company</CardTitle>
            </CardHeader>
            <CardBody>
              <RegisterOrganizationForm
                user={currentUser}
                onSubmit={handleSubmit}
              />
            </CardBody>
          </Card>
        </Container>
      </BannerSection>
    </Layout>
  );
};

const Connect = ({ location }) => (
  <DefaultStaticQuery
    render={(props) => <ConnectPage {...props} location={location} />}
  />
);

export default Connect;
